@import "../../mixins.scss";
@import "../../variables.scss";

.calendar {
  @include withFontStyles;

  align-items: center;
  box-sizing: border-box;
  color: $text;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(100% - #{$text-height}) $text-height;
  height: 100%;
  justify-content: space-between;
  justify-items: center;
  padding: 0 $space-m;
  row-gap: calc(#{$space-s} * 2);
  width: fit-content;

  .display {
    display: grid;
    height: fit-content;
    user-select: none;
    width: fit-content;

    .days {
      background-color: $earth;
      border-radius: $border-radius-l;
      padding: $space-m;
    }
    .month-year {
      background-color: $mercury;
      border-radius: $border-radius-l;
      margin: $space-s 0;
      padding: $space-s 0;
      text-align: center;
    }
    .row {
      display: flex;
      gap: $space-s;
      justify-content: left;
      margin: $space-s 0;
      
      .day,
      .weekday {
        padding: $space-s calc($space-s * 2);

        &.selected {
          background-color: $secondary;
          border-radius: $border-radius-l;
          color: $text;
        }
      }
      .day {
        color: $text-alt;
      }
      .day-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $space-s;
        height: 2rem;
      }
      .posted-dot-wrapper {
        display: flex;
        gap: 0.1rem;
        
        .posted-dot {
          background-color: $secondary;
          border-radius: 0.1rem;
          height: 0.2rem;
          width: 0.2rem;
        }
      }
    }
  }
  .controls {
    display: flex;
    gap: $space-m;
  }
}
