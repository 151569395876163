@import "../../variables.scss";

.card-deck {
  align-items: center;
  background-color: $secondary-opaque;
  border-radius: $border-radius-s;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-bottom: $space-s;
  width: 100%;
  
  .controls {
    display: flex;
    flex-direction: row;
    gap: $space-s;
    padding-bottom: $space-s;
    width: fit-content;
  }
  .cards-list {
    display: grid;
    gap: $space-m;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(2, auto);
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: calc($space-s * 3);
    
    li {
      width: fit-content;
    }
  }
}

@media screen and (max-width: $desktop-s) {
  .card-deck {
    .cards-list {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(4, auto);
    }
  }
}
