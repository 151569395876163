@import "../../../mixins.scss";
@import "../../../variables.scss";

/* This class is applied to BaseModal through the enum type */
.content.post,
.content.mock-post {
  .text {
    display: grid;
    grid-template-rows: max-content auto;
    height: calc(100vh - (#{$space-xl} * 2));
    padding: 0 $space-m;

    .header {
      >:first-child span {
        margin: $space-m 0;
      }
      >:last-child>:last-child {
        margin-bottom: $space-m;
      }
    }
    .body {
      @include withScrollbar;
      overflow-y: auto;
      margin-bottom: $space-m;
    }
  }
  .image, .default {
    background-color: $secondary;
    border-radius: $border-radius-l 0 0 $border-radius-l;
    height: 100%;
    max-height: 90vh;
    object-fit: contain;
    object-position: center;
    opacity: 0;
    width: 100%;
    
    &.show {
      animation-duration: 400ms;
      animation-iteration-count: 1;
      animation-name: show-image;
      opacity: 1;
    }
  }
}

@media (max-width: $desktop-s) {
  .content.post,
  .content.mock-post {
    .text {
      padding: 0 $space-s;
      
      .body {
        height: 58%;
      }
    }
    .image {
      border-radius: $border-radius-l $border-radius-l 0 0;
    }
  }
}

@keyframes show-image {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
