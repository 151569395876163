@import "./variables.scss";

@mixin withFontStyles {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: $font-size;
  text-align: left;
}

@mixin withScrollbar(
  $background-color: lighten($neptune, 20%),
  $foreground-color: $neptune,
) {
  // Firefox
  scrollbar-color: $foreground-color $background-color;
  scrollbar-width: thin;

  // Rest
  &::-webkit-scrollbar {
    width: 1em;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }
}
