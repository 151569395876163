@import "../../variables.scss";

.journal-entries {
  padding-bottom: $space-s;
  width: 100%;
  
  .entries {
    background-color: $secondary-opaque;
    border-radius: $border-radius-s;
    display: grid;
    gap: $space-m;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(2, auto);
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: calc($space-s * 3);
    
    li {
      width: fit-content;
    }
  }
}