@import "../../mixins.scss";
@import "../../variables.scss";

// Mixins
@mixin withHoverStyling(
  $base-color: $neptune
) {
  &:hover {
    background-color: darken($base-color, 10%);
  }
  &:active {
    background-color: lighten($base-color, 10%);  
  }
}

@mixin withThemes {
  &.jupiter {
    background-color: $jupiter;
    color: $text-alt;
  }
  &.mars {
    background-color: $mars;
    color: $text-alt;
  }
  &.neptune {
    background-color: $neptune;
    color: $text-alt;
  }
  &.uranus {
    background-color: $uranus;
    color: $text-alt;
  }
}

$icon-center: calc((#{$text-height} - #{$icon-size}) / 2 - #{$text-height});

.ui {
  @include withFontStyles;

  &.core {
    align-items: center;
    background-color: $secondary;
    border-radius: $border-radius-l;
    border: none;
    box-sizing: border-box;
    display: flex;
    height: $text-height;
    justify-content: space-between;
    margin: $space-s 0;
    padding: 0 $space-l $space-s;
    white-space: nowrap;
    width: fit-content;
  
    // Font colors
    color: $text;
    &.button,
    &.label-file,
    &.password,
    &.select,
    &.select .select-item,
    &.submit,
    &.text,
    &.text-area {
      color: $text-alt;
      span {
        color: $text-alt;
      }
    }
  
    // Text Alignment
    text-align: left;
    &.button,
    &.text-counter,
    &.label-file,
    &.submit {
      text-align: center;
    }
  
    // User selection
    user-select: none;
    &.password,
    &.text,
    &.text-area {
      user-select: unset;
    }
  
    // Actionable inputs
    &.button,
    &.label-file,
    &.select,
    &.select .select-item,
    &.submit {
      @include withHoverStyling;

      background-color: $neptune;
      cursor: pointer;
      transition: all $transition-s;

      &.disabled {
        background-color: $disabled-alt;
        color: $disabled;
        pointer-events: none;
      }
    }
  
    &.button {
      width: 100%;

      &:disabled {
        cursor: unset;
        color: $text;
        background-color: $secondary;
      }
      &.icon-only {
        padding: 0;
        min-width: $text-height;
        width: fit-content;
      }
    }

    &.card-label {
      color: $text;
      background-color: $secondary;
      border-radius: 0 0 $border-radius-s $border-radius-s;
      margin: 0;
      padding: 0;
      width: 100%;
      justify-content: center;
    }

    &.file {
      display: none;
    }

    &.header {
      border-radius: $border-radius-s $border-radius-s 0 0;
      margin: 0;
      height: 100%;
      padding: calc(#{$space-m} / 2) $space-l;
      width: 100%;
    }
  
    &.lines {
      @include withThemes;

      &.placeholder {
        background-color: $primary-opaque;
      }
      &.nowrap {
        display: block;
        max-width: 100%;
        overflow: hidden;
        padding-top: calc(#{$text-height} / 6);
        text-overflow: "..."; // Forces the use of styles class `ui` on ellipses.
        white-space: nowrap;
        width: 100%;
      }
      &.smaller {
        font-size: calc($font-size * 0.8);
      }
      &.smallest {
        font-size: calc($font-size * 0.7);
      }
      &.wrap {
        &.bottom {
          padding: 0 $space-m 0 $space-m;
          border-radius: $border-radius-s $border-radius-s $border-radius-l $border-radius-s;
          margin-bottom: $space-m;
        }
        &.middle {
          padding: 0 $space-l 0 $space-m;
          border-radius: $border-radius-s $border-radius-l $border-radius-l $border-radius-s;
        }
        &.top {
          border-radius: $border-radius-l $border-radius-l $border-radius-l $border-radius-s;
          padding-right: $space-m;
        }
      }
    }
  
    &.select {
      position: relative;
      width: 100%;

      .selected-item {
        vertical-align: middle;
        width: 100%;
      }
      .items-wrapper {
        display: none;
        &.open {
          display: block;
          padding-top: $space-l;
        }
        .items-list {
          border-radius: $border-radius-s;
          box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.3);
          left: 0;
          list-style-type: none;
          margin: 0;
          padding: 0;
          position: absolute;
          width: 100%;
          z-index: 1;
        }
        .select-item {
          @include withHoverStyling;

          align-items: center;
          background-color: lighten($neptune, 10%);
          display: flex;
          padding: $space-s $space-l $space-s $space-m;
          text-align: start;

          &:nth-child(2) {
            background-color: lighten($neptune, 5%);
          }
          &:first-child {
            border-radius: $border-radius-s $border-radius-s 0 0;
          }
          &:last-child {
            border-radius: 0 0 $border-radius-s $border-radius-s;
          }
        }
      }
    }
  
    &.submit {
      width: 100%;
    }
  
    &.password,
    &.text,
    &.text-area {
      background: unset;
      border-radius: 0;
      padding: 0 $space-s;
      width: 100%;
    }
  
    &.text-area {
      height: 6rem;
      resize: none;
      white-space: pre-wrap;
    }
  }

  &.icon {
    width: $icon-size;

    &.embed {
      align-self: center;
      pointer-events: none;
      position: absolute;
      left: calc($icon-size / 4);

      g {   
        fill: $text-alt;
      }
      &.align-end {
        left: unset;
        right: calc($icon-size / 4);
      }
      &.disabled {
        g {
          fill: $disabled;
        }
      }
      &.selected {
        g {
          fill: $selected;
        }
      }
    }
    &.light {
      g {
        fill: $text;
      }
      &.disabled {
        g {
          fill: $disabled-alt;
        }
      }
      &.selected {
        g {
          fill: $selected-alt;
        }
      }
    }
  }

  &.wrapper {
    width: 100%;

    &.button {
      position: relative;
      display: flex;

      &.icon-only {
        width: fit-content;
      }
    }
    &.card {
      align-items: center;
      background: none;
      border: none;
      border-radius: $border-radius-l $border-radius-l 0 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: flex-end;
      overflow: hidden;
      padding: 0;
      width: $card-size;

      .image {
        height: $card-size;
        object-fit: cover;
        opacity: 0;
        width: 100%;
        
        &.show {
          animation-duration: 400ms;
          animation-iteration-count: 1;
          animation-name: show-image;
          opacity: 1;
        }
      }
      >.default {
        background-color: $venus;
        width: $card-size;
        height: $card-size;
      }
      &.blank {
        opacity: 0.8;
        pointer-events: none;
        
        >.card-label {
          background-color: $mercury;
        }
        >.default {
          background-color: $mercury;
        }
        &.loading {
          >div,span {
            animation-duration: 1800ms;
            animation-iteration-count: infinite;
            animation-name: card-loading;
          }
        }
      }
    }
    &.counter {
      display: flex;
      gap: $space-s;
      margin: 0;
      width: fit-content;

      .ui.core.lines {
        align-content: space-around;
        padding: 0 0 $space-xs 0;
        text-align: center;
        width: $text-height;
      }
    }
    &.file {
      display: grid;
      grid-template-columns: 12rem calc(100% - 12rem);
      grid-auto-flow: column dense;
      justify-content: space-between;
      flex-direction: row;

      .ui.core.lines {
        color: $text-alt;
        background-color: $jupiter;
      }
    }
    &.header,
    &.lines {
      width: 100%;
    }
    &.text {
      position: relative;
      .bottom-border {
        background-color: $mars;
        border-radius: $border-radius-s;
        width: 100%;
        height: $border-radius-s;

        &.error {
          background-color: $error;
        }
      }
      .min-max {
        position: absolute;
        right: 0;
        font-size: calc($font-size * 0.7);
      }
    }
  }
}

@keyframes card-loading {
  0% {
    background-color: $mercury;
  }
  50% {
    background-color: $secondary;
  }
  100% {
    background-color: $mercury;
  }
}

@keyframes show-image {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}