@import "../../variables.scss";

.section {
  display: flex;
  gap: $space-m;
  margin: $space-m 0;
  padding: 0 $space-m;

  >:last-child {
    margin-bottom: $space-m;
  }
  &.column {
    flex-direction: column;

    &.centered {
      align-items: center;
    }
  }
  &.row {
    flex-direction: row;

    &.centered {
      justify-content: center;
    }
  }
}
