@import "../../variables.scss";

.notifier-list {
  margin: 0 $space-m;
  width: fit-content;

  /* animation-delay and animation-duration defined in Component */
  .notifier-item {
    animation-fill-mode: both;
    animation-name: show-notifier;
    animation-iteration-count: 2;
    animation-direction: alternate;
    position: relative;
    opacity: 0;
  }
}

@keyframes show-notifier {
  0% {
    opacity: 0;
    left: -4em;
  }
  10% {
    opacity: 0;
  }
  30%, 100% {
    opacity: 1;
    left: 0;
  }
}

@media screen and (max-width: $column-row-cutoff) {
  .notifier-list {
    align-self: flex-end;
  }
}
