@import '../../variables.scss';

.content-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(100% - #{$text-height}) $text-height;
  flex-direction: column;
  box-sizing: border-box;
  left: 0;
  padding: calc(#{$space-s} * 2);
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;

  >.main {
    display: grid;
    grid-template-columns: 10rem 40rem auto;
  }
  >.footer {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: $column-row-cutoff) {
  .content-container>.main {
    grid-template-columns: 100%;
    grid-template-rows: 3rem 40rem auto;
  }
}
