@import "../../variables.scss";

.app-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.debug div {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: solid red thin;
  box-sizing: border-box;
}
