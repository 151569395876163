@import "../../variables.scss";

.menu {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 10em;

  &.icons-only {
    width: fit-content;
  }
}

@media screen and (max-width: $column-row-cutoff) {
  .menu {
    flex-direction: row;
    justify-content: center;
    gap: $space-m;
    width: 100%;
  }
}
