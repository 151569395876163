@import "../../mixins.scss";
@import "../../variables.scss";

.content-wrapper {
  @include withScrollbar;
  background-color: $primary-opaque;
  border-radius: 0 0 $border-radius-l $border-radius-l;
  border-top: none;
  border: $secondary solid 0.2em;
  overflow-y: scroll;
  visibility: hidden;

  .hide {
    opacity: 0;
    visibility: hidden; 
  }
}
