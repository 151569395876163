@import "../../../variables.scss";

.page-container {
  height: fit-content;
  width: $page-container-size;
  
  &.fit-content {
    width: fit-content;
  }
}
