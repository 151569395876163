// Colors
$primary: #e5e3c3;
$primary-opaque: #e5e3c37c;
$secondary: #645d55;
$secondary-opaque: #645d55af;
$tertiary: #afa599;

$disabled: #d5c3b1;
$disabled-alt: #7b7065;
$error: #e52b2b;
$selected: #d5c3b1;
$selected-alt: #7b7065;
$success: #38d154;

$text: #ffffff;
$text-alt: #38322c;

$shadow: #0000007c;

$earth: #94a0af;
$jupiter: #cfcfaf;
$mars: #a48b8b;
$mercury: #958e7e;
$neptune: #90a3aa;
$saturn: #decdbc;
$sol: #b1ae96;
$uranus: #9cb8a1;
$venus: #cbbdac;

// Dimens
$font-size: 1rem;
$icon-size: 1.2rem;
$page-container-size: 30%;
$card-size: 8rem;
$space-xl: 4rem;
$space-l: 2rem;
$space-m: 1rem;
$space-s: 0.2rem;
$space-xs: 0.1rem;
$text-height: 1.8rem;
$loading-bar-size: 0.6rem;

$border-radius-l: calc(#{$text-height} / 2);
$border-radius-s: calc(#{$text-height} / 4);

$max-modal-size: 1800px;

// Screen size
$mobile: 576px;
$desktop-s: 768px;
$column-row-cutoff: 1000px;

// Time
$transition-s: 0.2s;

// Misc
$neg: -1;
