/* Dead Simple Grid (c) 2015 Vladimir Agafonkin */

.row .row { margin: 0 0.8em; }
.col      { padding: 0 0.8em; }

.row:after {
    content: "";
    clear: both;
    display: table;
}

@media only screen { .col {
    float: left;
    width: 100%;
    box-sizing: border-box;
}}
