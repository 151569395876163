@import "../../variables.scss";

.sol {
  background-color: $primary;
  background-image: url("./icons/bg.svg");
  background-repeat: repeat;
  display: flex;
  height: 100%;
  width: 100%;
  
  .print-flash {
    animation-duration: 1000ms;
    animation-name: hide-flash;
    background-color: transparent;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1000;
  }
}

@keyframes hide-flash {
  0% {
    background-color: white;
    opacity: 1;
  }
  100% {
    background-color: white;
    opacity: 0;
  }
}
