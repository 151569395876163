@import "../../../variables.scss";

.modal-wrapper {
  align-items: center;
  background-color: $shadow;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;

  .modal {
    display: grid;
    grid-template-rows: calc(#{$icon-size * 2}) auto;
    max-height: calc(100% - #{$space-l});
    max-width: $max-modal-size;
    width: 90%;

    .modal-menu {
      align-items: center;
      display: flex;
      gap: calc($space-m / 2);
      height: fit-content;
      justify-content: flex-end;
      width: 100%;
    }
    .content {
      background-color: $primary;
      background-image: url("./icons/bg.svg");
      background-repeat: repeat;
      border-radius: $border-radius-l;
      display: grid;
      grid-template-columns: 60% auto;
      height: 90vh;
    }
  }
}

@media (max-width: $desktop-s) {
  .modal-wrapper {
    .modal {
      max-height: unset;
      height: 100%;
      width: 99%;

      .content {
        grid-template-columns: 100%;
        grid-template-rows: 40% 50rem;
        height: calc(100vh - 5%);
      }
    }
  }
}
