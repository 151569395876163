@import "../../variables.scss";

.loading-indicator {
  background-color: transparent;
  height: $loading-bar-size;
  width: 100%;

  .bar {
    background-color: transparent;
    min-width: $loading-bar-size;
    height: $loading-bar-size;
    width: 100%;
  }

  &.rounded,
  &.rounded>.bar {
    border-radius: calc($loading-bar-size / 2);
  }
}
