@import "./mixins.scss";
@import "./app/dead-simple-grid/grid.css";

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  overflow: hidden;
}

// Utily: used to measure text to properly display wrapped text.
// Expects <span id="ruler" /> in index.html
#ruler {
  @include withFontStyles;
}
